export default [
  {
    header: '',
    items: [
      {
        path: '/#home',
        label: 'Home',
      },
      {
        path: '/#distributors',
        label: 'Distributors',
      },
      {
        path: '/#workshops',
        label: 'Workshops',
      },
      {
        path: '/#features',
        label: 'Features',
      },
      {
        path: '/#contact',
        label: 'Contact',
      },
    ],
  },
  {
    header: 'Our Information',
    items: [
      {
        path: '/about-us',
        label: 'About Us ',
      },
      {
        path: '/privacy-policy',
        label: 'Privacy Policy',
      },
      {
        path: '/terms',
        label: 'Terms & Conditions',
      },
    ],
  },
  {
    header: 'Social media',
    items: [
      {
        path: 'https://www.facebook.com/garaazapp/',
        label: 'Facebook',
      },
      {
        path: 'https://twitter.com/Garaazapp?t=hKn4OLAH0XyoQpqM04xkIg&s=08',
        label: 'Twitter ',
      },
      {
        path: 'https://instagram.com/garaazapp?igshid=YmMyMTA2M2Y',
        label: 'Instagram',
      },
      {
        path: 'https://www.linkedin.com/company/garaaz/',
        label: 'LinkedIn',
      }
    ],
  },
  // {
  //   header: 'Policy',
  //   items: [
  //     {
  //       path: '/',
  //       label: 'Application security',
  //     },
  //     {
  //       path: '/',
  //       label: 'Software principles',
  //     },
  //     {
  //       path: '/',
  //       label: 'Unwanted software policy',
  //     },
  //     {
  //       path: '/',
  //       label: 'Responsible supply chain',
  //     },
  //   ],
  // },
];
